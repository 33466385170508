export function debounce (func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export function disableSaveBtn (value) {
  const saveBtn = document.querySelector('#saveBtn')
  if (saveBtn) {
    if (value && !saveBtn.classList.contains('default-enabled')) {
      saveBtn.classList.replace('btn-primary', 'btn-disabled')
      saveBtn.disabled = true
    } else {
      saveBtn.classList.replace('btn-disabled', 'btn-primary')
      saveBtn.disabled = false
    }
  }
}
